/* eslint-env node */

/*
 * This file is automatically compiled by Webpack, along with any other files
 * present in this directory. You're encouraged to place your actual application logic in
 * a relevant structure within app/javascript and only use these pack files to reference
 * that code so it'll be compiled.
 */

/* eslint-env node */
import Rails from '@rails/ujs';
import Turbolinks from 'turbolinks';

Rails.start();
Turbolinks.start();

const componentRequireContext = require.context('components', true);
const ReactRailsUJS = require('react_ujs');
ReactRailsUJS.useContext(componentRequireContext);

document.addEventListener('click', e => {
  // this function allows all modals to be closed when clicking outside the dialog
  const activeModal = document.getElementsByClassName('active modal')[0];

  if (activeModal === e.target) {
    window.location.hash = 'all';
  }
}, false);